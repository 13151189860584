import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import HomeDesktop from "../../images/projects/grownas/home--scroll.mp4"
import ProjectCard from "../../components/project-card"
import GoSun from "../../images/projects/gosun/cover--2.jpg"
import JackRabbit from "../../images/projects/jack-rabbit/cover.jpg"

const grownasMac = () => (
  <Layout>
    <SEO title="GrownAs Mac" />
    <WorkInfo 
      title="GrownAs Mac"
      link="grownasfoods.com"
      team1="Bailey Latimer"
      team2="Michael Budde"
      challenge="GrownAs Mac is not your ordinary mac & cheese. The brand features all-vegan ingredients and packs a kick-ass personality. To beat the market, they wanted us to deliver an out-of-the-box experience in less than two weeks."
      solution="We delivered a fun, motion-centric experience that embodies the brand voice and aesthetic before the deadline. Working closely with the client allowed ideas to flow and achieve a unique final product."/>
      <video  class="mb-6"
        loop
        muted
        autoPlay>
        <source src={HomeDesktop} type="video/mp4" />
      </video>


      <div class="columns-1 lg:columns-2 mb-6">
        <StaticImage src="../../images/projects/grownas/browser.jpg" alt="GrownAs Mac Browser Preview" aspectRatio={1/1} layout={"fullWidth"}/>
        <StaticImage src="../../images/projects/grownas/mobile--01.jpg" alt="GrownAs Mac Mobile Preview" aspectRatio={1/1} layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-20" src="../../images/projects/grownas/mobile--02.jpg" alt="GrownAs Mac Mobile Preview" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
        <ProjectCard classes="" title="GoSun" url="gosun" description="Creating a winning brand, design system, and e-commerce experience to disrupt the outdoors market. Raised $2m+ via crowdfunding and secured Series A funding in first 8 months." cover={GoSun}  role="Design + Development + Branding"/>

          <ProjectCard classes="" title="JackRabbit" url="jackrabbit"  description="Making commuting fun again." cover={JackRabbit}  role="Development"/>
        </ul>
      </div>


  </Layout>
)

export default grownasMac
